import { Link as Browse} from "react-router-dom";
import React from "react";

function Header() {
  return (
      <header>
          <img src="../img/trio.png" className="App-logo" />
          <h1>NEW ALBUM IS OUT!</h1>
          <ul className="bar">
          <li>
              <Browse className="btn" style={{display: 'flex', flexDirection: 'row'}} to="/album">Click here for our new album!</Browse>
          </li>
          </ul>
      </header>
  );
}
export default Header;

