function About() {
  return (
    <>
      <div style={{marginTop:'10rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="../img/img1.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
              Giuffre Memorial TRIO, comprised of Roger Arve Vigulf (clarinet), Per Christian Arnesen (bass) and Bjørn Norvall Hoemsnes (piano), was created in July 2021 to pay homage to the legendary Free Fall recording from 1963 by Jimmy Giuffre, Paul Bley and Steve Swallow. In tradition with free improvisation genre, our music is made on the spot at the moment. We refer to this as “compovisation” (not “improvisation”), since we are classically trained musicians and composers - not jazz-musicians. The album title The Five Ways refer to one of the tracks from the 1963-album. Our aim is to bring the temperament and atmosphere of Free Fall forward in present time. Release of our debut-album is expected November 2022.
            </p>
            <h2 className="main-title about-h2">Contact</h2>
            <p className="main-p">
              More info and booking: contact@giuffrememorialtrio.com </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
