
import {Container} from "react-bootstrap";

function YoutubePage() {
  return (
    <>
        <Container>
            <div style={{marginTop:'10rem',width:'100%',height:'10px'}} className="about-scroll"></div>
            <div className="container about">
                <h1> Selected YouTube videos</h1>
                <p className="blog">
                    {"\nThe Five Ways, Horten, June 16, 2022:"}</p>
            <div
                className="video"
                style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                }}
            >
                <iframe
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                    }}
                    src="https://www.youtube.com/embed/dRNZWrRb1Bs"
                    frameBorder="0"
                />
            </div>

                <p className="blog">
                    {"\n\nThe Second Way (The Five Ways), Horten, October 10, 2021:"}</p>
                <div
                    className="video"
                    style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                    }}
                >
                    <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                        }}
                        src="https://www.youtube.com/embed/yo4qzI_oAXo"
                        frameBorder="0"
                    />
                </div>
                <p className="blog">
                    {"\n\nRejoice, Horten, June 16, 2022:"}</p>
                <div
                    className="video"
                    style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                    }}
                >
                    <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                        }}
                        src="https://www.youtube.com/embed/Ee4-LQx3UoY"
                        frameBorder="0"
                    />
                </div>

                <p className="blog">
                    {"\n\nCliffhanger, Horten, November 19, 2022:"}</p>
                <div
                    className="video"
                    style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                    }}
                >
                    <iframe
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                        }}
                        src="https://www.youtube.com/embed/TAuhLthUfLs"
                        frameBorder="0"
                    />
                </div>
            </div>
        </Container>

    </>
  );
}
export default YoutubePage;

