import Navbar from "./pages/Navbar";
import Header from "./pages/Header";
import Main from "./pages/Main";
import Footer from "./pages/Footer";
import Page from "./pages/AboutPage/Page";
import About from "./pages/About";

function Home() {
  return (
    <>
      <Header />
      <About/>
    </>
  );
}

export default Home;
