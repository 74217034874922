import YoutubePage from "./YoutubePage";

function Youtube() {
    return (
        <>
            <YoutubePage/>
        </>
    );
}

export default Youtube;