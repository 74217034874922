import Footer from "../Footer";
import Header from "../Header";
import Main from "../Main";
import About from '../About';
import UpComing from "../UpcomingEvents/UpComing";
import NewsAndRewievs from "./NewsAndRewievs";

function Page() {
    return (
        <>
            <NewsAndRewievs />
        </>
    );
}

export default Page;