import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {
  return (
    <footer>
      <div className="container foot">
        <div className="row">
          <div className="side1">
              <p className="siste_ord">GIUFFRE MEMORIAL TRIO
                  2022 © Vigulf/Arnesen/Hoemsnes</p>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>
    </footer>
  );
}
export default Footer;
