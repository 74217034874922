import {Container} from "react-bootstrap";
import Contact from "../pages/contactPage/Contact";

function AlbumPage() {
    return (
        <>
            <Container>
                <div style={{marginTop:'10rem', width:'100%',height:'1px'}} className="upcoming"></div>
                <div className="container upcoming">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="main-title about-h2">Track list:</h2>
                            <p className="main-p">1. Past Mistakes 2:09 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                2. Divided Man 0:44 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                3. The Five Ways 8:40 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                4. Four Brothers 0:51 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                5. Rejoice 2:46 (Bjørn Norvall Hoemsnes)</p>

                            <p className="main-p">
                                6. Pompel & Pils 1:24 (Per Christian Arnesen)</p>

                            <p className="main-p">
                                7. Stones from a Garage 3:58 (Roger Arve Vigulf)</p>

                            <p className="main-p">
                                8. The Second Way 9:31 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                9. Odessa Blue Pills 3:39 (Vigulf, Arnesen, Hoemsnes)</p>

                            <p className="main-p">
                                10. Moskva 5:03 (Vigulf, Arnesen, Hoemsnes)</p>

                            <p className="main-p">
                                11. Capetown 3:27 (Vigulf, Arnesen, Hoemsnes)</p>

                            <p className="main-p">
                                12. A Third Way (Not the Last) 8:42 (Jimmy Giuffre)</p>

                            <p className="main-p">
                                {"13. Cliffhanger 0:45 (Vigulf, Arnesen, Hoemsnes)\n\n\n\n"}</p>

                            <p className="main-p">
                                {"\n\n\n\nTrack 1, 2, 3, 4, 5, 6, 9, 10, 11: Recorded by Asbjørn Kjølberg, Kjølberg Produksjon AS, Kammersalen, Horten, June 16, 2022"}</p>

                            <p className="main-p">Track 8, 13: Recorded by Roger Arve Vigulf, Teiesalen, Tønsberg, June 15, 2022</p>

                            <p className="main-p">Track 7, 12: Recorded by Roger Arve Vigulf, Kammersalen, Horten, October 10, 2021</p>

                        </div>
                        <div className="col-md-6 text-center">
                            <img alt="about" src="../img/plate.png" className="upComing" />
                            <img alt="about" src="../img/plate2.png" className="upComing" />
                            <p>
                                Released November 18, 2022
                            </p>
                            <p>
                                Cover photo by Roger Arve Vigulf
                            </p>
                            <p>
                                Produced and Mastered by Rick Romano
                            </p>
                            <p>
                                Designed by RMN Classical
                            </p>
                            <a className="btn" style={{ cursor: "pointer" }} href="https://rmnmusic.com/the-five-ways/">
                                Listen here
                            </a>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    );
}
export default AlbumPage;