import UpComing from "./UpComing";


function UpComingPage() {
    return (
        <>
            <UpComing/>
        </>
    );
}

export default UpComingPage;