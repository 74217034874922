import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from "./Layout";
import Home from './Home';
import Page from './pages/AboutPage/Page';
import UpComingPage from './pages/UpcomingEvents/UpComingPage';
import Youtube from "./pages/Youtube/Youtube";
import Album from "./AlbunPage/Album";

  ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="page" element={<Page />} />
                  <Route path="upcoming" element={<UpComingPage />} />
                  <Route path="links" element={<Youtube />} />
                  <Route path="album" element={<Album />} />
                  <Route path="*" element={<Home />} />
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

