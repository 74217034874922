import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Navigation() {
    return (
        <nav>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/*">Home</Link>
                </li>
                <li>
                    <Link to="/page">Page</Link>
                </li>
                <li>
                    <Link to="/upcoming">UpComingPage</Link>
                </li>
                <li>
                    <Link to="/links">Youtube</Link>
                </li>
                <li>
                    <Link to="/album">Album</Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navigation;