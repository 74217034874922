function About() {
  return (
    <>
      <div style={{marginTop:'10rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <h1> News and Reviews</h1>
        <p className="blog">
            {"\nwildernessviking.blogspot.com"}</p>
          <div className="link">
            <a href="https://wildernessviking.blogspot.com/2023/01/giuffre-memorial-trio-2022-five-ways.html">
                Giuffre Memorial Trio - 2022 - The Five Ways
            </a>
          </div>
        <p className="blog">
            {"\n\nspjelkavika.no"}</p>
          <div className="link">
            <a href="https://spjelkavika.no/nyheter-publisher/1545-vigulf-plateaktuell">
                Vigulf plateaktuell
            </a>
          </div>
        <p className="blog">
            {"\n\nballade.no"}</p>
          <div className="link">
            <a href="https://www.ballade.no/arrangementer/giuffre-memorial-trio-pa-horten-kulturskole-kammersal/">
                Giuffre Memorial TRIO på Horten kulturskole, Kammersal
            </a>
          </div>
      </div>
    </>
  );
}
export default About;
