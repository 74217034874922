import { Link } from "react-scroll";
import React from "react";
import { Link as Browse} from "react-router-dom";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }

  return (
    <nav className="navbar">
      <div className="container">
        <li>
          <Browse to="/"> </Browse>
        </li>
        <div className="row">
          <h1 className="logo">
            <Link
              spy={true}
              smooth={true}
              duration={1000}
              to="/"
              style={{ cursor: "pointer" }}
            >
              GIUFFRE MEMORIAL TRIO
            </Link>
          </h1>
          <ul className="bar">
            <li>
              <Browse to="/">Home</Browse>
            </li>
            <li>
              <Browse to="/page">News and reviews</Browse>
            </li>
            <li>
              <Browse to="/upcoming">Activities and history</Browse>
            </li>
            <li>
              <Browse to="/links">YouTube links</Browse>
            </li>
            <li>
              <a href="https://open.spotify.com/artist/2j6Jwld0pSzu8r3ZSjNNmd?si=xXg8-Au-Rh-eakHm6215Bw">
                Spotify
              </a>
            </li>
          </ul>
          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
