function UpComing() {
    return (
        <>
            <div style={{marginTop:'10rem', width:'100%',height:'100%'}} className="upcoming"></div>
            <div className="container upcoming">
                <div className="row">
                    <div className="col-md-8">
                        <h2 className="main-title about-h2">2023</h2>
                        <p className="main-p">
                            Planning upcoming events</p>
                        <h2 className="main-title about-h2">2022</h2>
                        <p className="main-p">
                            December 8: Release-concert, Cafe URO, Tønsberg </p>

                        <p className="main-p">
                            November 30: Fourth rehearsal, Hvasser</p>

                        <p className="main-p">
                            November 19: Prerelease-concert, Horten</p>

                        <p className="main-p">
                            November 18: Release date The Five Ways</p>

                        <p className="main-p">
                            November 5: Consert, Horten, with Ensemble KammerKlang</p>

                        <p className="main-p">
                            August 12: Third rehearsal, Hvasser</p>

                        <p className="main-p">
                            June 16: Concert Horten, Kammersalen</p>

                        <p className="main-p">
                            June 15: Concert Tønsberg, Teie-salen</p>

                        <p className="main-p">
                            April 23: Second rehearsal, Hvasser</p>

                        <h2 className="main-title about-h2">2021</h2>

                        <p className="main-p">
                            October 10: Recording, Kammersalen, Horten</p>

                        <p className="main-p">
                            July 22: Our first rehearsal, Hvasser</p>

                    </div>
                    <div className="col-md-6 text-center">
                        <img alt="about" src="../img/upcoming.png" className="upComing" />
                        <p className="pic-p">
                            Concert November 5, 2022, Kammersalen, Horten kulturskole
                            Photo:Bartosz Sosnowski
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UpComing;
