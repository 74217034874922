import AlbumPage from "./AlbumPage";

function Album() {
    return (
        <>
            <AlbumPage/>
        </>
    );
}

export default Album;